<template>
  <div class="merchant-home">
    <div class="head">
      <img class="user-head-img" :src="userHeadImg" alt="">
      <div class="user-info">
        <span class="user-name">{{userName}}</span>
        <span class="exit-btn" @click="exit">退出登录</span>
      </div>
      <div class="bg" :style="bgImg"></div>
    </div>
    <div class="container">
      <div class="fn-box">
        <div class="fn-box-title">
          常用功能
        </div>
        <div class="fn-common-box">
          <div class="fn-common-item"  @click="toNext('/merchant/car-search')">
            <img src="@/assets/image/merchant-home-lcpzs.png" alt="" class="common-img">
            <div>录车牌赠送</div>
          </div>
          <div class="fn-common-item"  @click="toNext('/merchant/qrcode-dynamic')">
            <img src="@/assets/image/merchant-home-dtewm.png" alt="" class="common-img">
            <div>动态二维码</div>
          </div>
          <div class="fn-common-item"  @click="toNext('/merchant/qrcode-paper')">
            <img src="@/assets/image/merchant-home-zzewm.png" alt="" class="common-img">
            <div>纸质二维码</div>
          </div>
        </div>
      </div>
      <div class="fn-box">
        <div class="fn-box-title">
          基本功能
        </div>
        <div class="fn-item" @click="toNext('/merchant/qrcode-static')">
          <img src="@/assets/image/merchant-home-gdewm.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title">固定二维码</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div>
        <div class="fn-item" @click="toNext('/merchant/recharge')">
          <img src="@/assets/image/merchant-home-shcz.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title">商户充值</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div>
        <div class="fn-item" @click="toNext('/merchant/recharge-records')">
          <img src="@/assets/image/merchant-home-czjl.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title">充值记录</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div>
        <div class="fn-item" @click="toNext('/merchant/present-records')">
          <img src="@/assets/image/merchant-home-zsjl.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title">赠送记录</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div>
        <div class="fn-item" @click="toNext('/merchant/update-pwd')">
          <img src="@/assets/image/merchant-home-pwdset.png" alt="" class="left-icon">
          <div class="item-cont">
            <span class="title">密码设置</span>
          </div>
          <img src="@/assets/image/home-right.png" alt="" class="right-icon">
        </div>                      
      </div>
    </div>
  </div>
</template>

<script>
import avatar from '@/assets/image/photo.png';

export default {
  name: 'MerchantHome',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      merchantInfo: null,
      userHeadImg: '',
      bgImg: {
        background: '',
        'background-position': 'center',
        'background-size': 'cover'
      },
      userName: '用户名',
    }
  },
  computed: {},
  watch: {},
  created () {
    this.merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))
    this.userHeadImg = this.merchantInfo.userHead?'https://image.scsonic.cn/'+this.merchantInfo.userHead:avatar
    this.bgImg.background = 'url(' + this.userHeadImg + ')'
    this.userName = this.merchantInfo.merchantsName
  },
  mounted () {},
  destroyed () {},  
  methods: {
    toNext (path) {
      this.$router.push(path)
    },
    exit() {
      localStorage.removeItem("merchantInfo")
      this.$router.push('/merchant/login')
    }
  },
}
</script>

<style scoped lang="scss">
.merchant-home{
  .head{
    height: 300px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    .bg{
      // background: url('../../assets/image/avatar.jpg');
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      filter: blur(15px);
      background-position: center;
      background-size: cover;
    }
    .user-head-img{
      width: 132px;
      height: 132px;
      border-radius: 50%;
      margin-left: 53px;
      margin-right: 40px;
    }
    .user-info{
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
    .user-name{
      font-size: 44px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 20px;
    }
    .exit-btn{
      width: 161px;
      height: 47px;
      line-height: 43px;
      text-align: center;
      border: 2px solid #FFFFFF;
      border-radius: 24px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      box-sizing: border-box;
    }
  }
  .container{
    background: #FFFFFF;
    // box-shadow: 1px 4px 20px 0px rgba(174, 186, 190, 0.7);
    border-radius: 33px 33px 0px 0px;
    position: relative;
    top: -40px;
    text-align: left;
    padding: 25px;
    .fn-box{
      // margin-bottom: 47px;
      .fn-box-title{
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-left: 17px;
      }
      .fn-common-box{
        width: 100%;
        display: flex;
        justify-content: space-around;
        font-size: 24px;
        font-weight: bold;
        color: #414141;
        margin-top: 28px;
        margin-bottom: 40px;
        text-align: center;
        .common-img{
          width: 100px;
          height: 100px;
          margin-bottom: 20px;
        }
      }
      .fn-item{
        display: flex;
        align-items: center;
        height: 80px;
        border-bottom: 1px solid rgba(145, 145, 145, .3);
        box-sizing: border-box;
        position: relative;
        padding: {
          top: 37px;
          left: 21px;
          right: 17px;
          bottom: 16px;
        };
        .left-icon{
          width: 32px;
          height: 32px;
          margin-right: 23px;
        }
        .right-icon{
          width: 12px;
          height: 23px;
          position: absolute;
          right: 17px;
        }
        .item-cont{
          display: flex;
          flex-direction: column;
          .title{
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #424242;
          }
          .des{
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #9A9A9A;
          }
        }
      }
    }
  }
}
</style>