import wx from 'weixin-js-sdk'
import { apiSignPackage } from "@/utils/api"
import { Toast, Notify } from 'vant'

const signMap = new Map()

const domain = window.location.origin

const wxShareInfo = {}

const wxUtils = {
  wxShare (shareInfo){
    wxShareInfo.title = shareInfo.title || '分享标题'
    wxShareInfo.desc = shareInfo.desc || '分享描述'
    wxShareInfo.link = shareInfo.link || ' 分享链接'
    wxShareInfo.imgUrl = shareInfo.imgUrl || '分享图标'

    let authUrl = shareInfo.link
		if (signMap.has(authUrl)) {
			this.setWxConfig(signMap.get(authUrl))
		} else {
			this.getSignature({url:domain+'/merchant/qrcode-paper', qrcodeId:shareInfo.qrcodeId})
		}
  },

  getSignature (param){
    console.log(param)
    apiSignPackage(param)
    .then(result => {
      if (result.status == "200") {
        signMap.set(param.qrcodeId, result.data)
        this.setWxConfig(result.data)
      }else {
        Notify({ type: "danger", message: result.message })
      }
    })
    .catch(err => {
      window.console.log(err);
    })
  },

  setWxConfig (sign){
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: sign.appId, // 必填，公众号的唯一标识
      timestamp: sign.timestamp, // 必填，生成签名的时间戳
      nonceStr: sign.noncestr, // 必填，生成签名的随机串
      signature: sign.signature,// 必填，签名
      jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的JS接口列表
    })

    wx.ready(function(){
      console.log('ready...')
      let {title, desc, link, imgUrl} = wxShareInfo
      wx.updateAppMessageShareData({ 
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success: function () {
          // 设置成功
        }
      })
    })

    wx.error(function(res){
      console.log(res)
      this.getSignature(wxShareInfo.link)
    })
  }
}

export default wxUtils