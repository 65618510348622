<template>
  <div id="authorInfo">
    <div class="authorImg">
      <img src="../../assets/image/shouquan@2x.png" alt style="width: 210px;height: 130px;" />
    </div>
    <div class="info">
      <div class="infotitle">温馨提示</div>
      <div class="desc">
        <div class="deschui">为了确保服务的正常使用和给您更好的体验</div>
        <div class="desclan">您授权后，可体验本次完整服务~</div>
      </div>
      <van-button class="search" @click="toSkip" type="primary" size="large" color="#008CFF">同意授权</van-button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  components: {

  },
  created() {

  },
  mounted() {

  },
  destroyed() {

  },

  methods: {
    toSkip() {
       let skipUrl=this.getUrlParam('skipUrl');
      let newurl = decodeURIComponent(skipUrl)+"&isDesc=true";
       window.location.href = newurl;
      // if (!code) {
      //   const param = {
      //     p: wxPublic(),
      //     authType: "snsapi_userinfo", //提示用户授权
      //     redirectUrl:skipUrl
      //   };
      //   apiCodeUrl(param)
      //       .then(result => {
      //         var res = result.data;
      //         window.console.log(res);
      //         if (res.status == "success") {
      //           window.location.href = res.data;
      //         }
      //       })
      //       .catch(err => {
      //         window.console.log(err);
      //       });
      // } else {
      //   window.location.href = skipUrl+"&&code="+code;
      // }
    },
    //获取路径参数
    getUrlParam(variable) {
      var query = location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
      }
      return(false);
    }
  }
};
</script>

<style scoped >
#authorInfo {
  text-align: center;
}

.authorImg {
  text-align: center;
  margin-top: 140px;
  margin-bottom: 60px;
}



.info .infotitle {
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.info .desc {
  margin-top: 50px;
  margin-bottom: 70px;
  padding: 0 100px;
  font-size: 28px;
  font-family: PingFang SC;

}

.deschui {
  color: #9EA3AD;
  margin-bottom: 10px;
}

.desclan {
  color: #008CFF;
}

.search {
  width: 80%;
}
</style>
