<template>
  <div class="recharge">
    <img class="header-bg" src="@/assets/image/bjinformation2.png" alt="">
    <div class="recharge-container">
      <div class="recharge-form">
        <div class="form-item">
          <span class="title">充值规则</span>
          <van-radio-group class="recharge-ruletype" v-model="rechargeType" icon-size="14px" direction="horizontal" @change="switchRechargeType">
            <van-radio name="0">时间账户</van-radio>
            <van-radio name="1">金额账户</van-radio>
          </van-radio-group>
          <select id="" v-model="ruleId" class="form-select" @change="selectFn($event)">
            <!-- <option value="">请选择充值规则</option> -->
            <option v-for="item in ruleList" :key="item.ruleId" :value="item.ruleId">{{item.ruleName}}</option>
          </select>
        </div>
        <div class="form-item">
          <span class="title">份数</span>
          <input type="number" @input="changeNum" v-model="servings" class="form-input" placeholder="请输入购买份数（数字）">
        </div>
        <div class="form-item">
          <span class="title">充值额度</span>
          <input type="text" class="form-input" v-model="limit" readonly placeholder="充值额度">
        </div>
        <div class="form-item">
          <span class="title">有效期</span>
          <input type="text" class="form-input" v-model="useLimitDate" readonly placeholder="有效期">
        </div>
        <div class="form-item payment-type">
          <span class="title">支付方式</span>
          <van-radio-group v-if="payList.length != 0" v-model="payment" checked-color="#FF7C2D">
            <van-radio v-for="item in payList" v-show="item.preCode != '120'" :key="item.preCode" :name="item.preCode" label-position="left" ><img class="paytype-icon" :src="'http://image.scsonic.cn/'+item.payIcon" alt="">{{item.payName}}</van-radio>
          </van-radio-group>
          <p v-else class="no-payment">未查询到支付方式，请联系管理员</p>
        </div>
        <div style="height:1px;"></div>
      </div>
      <div class="footer">
        <span class="pay-amount">实付金额：￥{{amountTotal}}</span>
        <van-button class="linear-gradient pay-btn" type="info" @click="pay">立即支付</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { accountInfo, apiGetPayList, merchantsRule, xpxPay } from "@/utils/api"
import { Toast, Notify } from 'vant'

export default {
  name: 'MerchantRecharge',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      merchantInfo: null,
      rechargeType: '0',
      accountInfo: '',
      payList: [],
      payment: '',
      ruleList: [],
      ruleId: '',
      ruleName: '',
      servings: 1,
      limit: '',
      useLimitDate: '',
      amountTotal: 0,
      openid: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))
    const urlParam = this.$route.query
    this.openid = urlParam.openid

    this.getAccountInfo()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getAccountInfo () {
      let that = this

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
      }
      accountInfo(param)
      .then(result => {
        if (result.status == "200") {
          that.accountInfo = result.data
          that.getPayList()
          that.getMerchantsRule()
        }else {
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    getPayList () {
      let that = this

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
        parkingLotId: this.accountInfo.parkinglotId,
        client: 'A',
      }
      apiGetPayList(param)
      .then(result => {
        if (result.status == "200" && result.data.length != 0) {
          that.payList = result.data
          that.payment = result.data[0].preCode
        }else {
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    getMerchantsRule () {
      let that = this

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
        parkingLotId: this.accountInfo.parkinglotId,
        ruleName:"",
        ruleType:this.rechargeType
      }
      merchantsRule(param)
      .then(result => {
        if (result.status == "200" && result.data.length != 0) {
          that.ruleList = result.data
          that.ruleId = result.data[0].ruleId
          that.ruleName = result.data[0].ruleName
          that.renderData()
        }else if(result.status == "200"){
          that.ruleList = []
          that.ruleId = ''
          Notify({ type: "danger", message: "未查询到充值规则" })
        }else{
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    switchRechargeType (){
      this.getMerchantsRule()
    },
    selectFn (e){
      this.ruleName = this.ruleList[e.target.selectedIndex].ruleName
      this.renderData()
    },
    changeNum (){
      this.servings = this.servings.length>6 ? this.servings.slice(0,6) : this.servings
      this.renderData()
    },
    renderData (){
      let ruleObj = this.ruleList.find(e => e.ruleId == this.ruleId)

      if(this.servings == '' || this.servings < 0){
        this.limit = ''
        this.amountTotal = 0
        this.useLimitDate = ''
      }else{
        this.limit = (parseFloat(this.servings)*(parseFloat(ruleObj.ruleQuota)*1000000))/1000000 + (ruleObj.ruleType==0?' 小时':' 元')
        this.amountTotal = (parseFloat(this.servings)*(parseFloat(ruleObj.ruleAmount)*1000000))/1000000
        if(ruleObj.useLimitEnd == 0){
          this.useLimitDate = "不限制"
        }else{
          let starTime = this.getNowFormatDate()
          let time = this.calculateDate(parseInt(ruleObj.useLimitEnd) * parseInt(this.servings))
          this.useLimitDate = !time ? '' : (starTime + " 到 " + time)
        }
      }
    },
    pay () {
      let that = this
      if(this.servings == '' || this.servings < 1){
        Notify({ type: "danger", message: "请输入购买份数（数字）" })
        return
      }

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
        sceneType: this.payment+"A8002",
        parkingLotId: this.accountInfo.parkinglotId,
        // sceneType: '102A8002',
        // parkingLotId:'01982903ca124ea4a311ec33de56aaba',
        amountTotal: this.amountTotal,
        amountDiscount: 0,
        amountCurrentPay: this.amountTotal,
        dataId: this.ruleId,
        incomeName: this.ruleName,
        openId: this.openid,
        servings: this.servings,
        accountId: this.accountInfo.accountId
      }

      xpxPay(param)
      .then(result => {
        if (result.status == "200") {
          if(result.data.payUrl != ''){
            window.location.href = result.data.payUrl
          }else{
            let obj = result.data.jsApiObject
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                "appId":obj.appId,     //公众号名称，由商户传入     
                "timeStamp":obj.timeStamp,         //时间戳，自1970年以来的秒数     
                "nonceStr":obj.nonceStr, //随机串     
                "package":obj.package,     
                "signType":obj.signType,         //微信签名方式：     
                "paySign":obj.paySign //微信签名 
              },
              function(res){
                if(res.err_msg == "get_brand_wcpay_request:ok" ) {// 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
                  Toast.success('充值成功')
                }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
                  Notify({ type: "danger", message: "您已取消支付" })
                }else{
                  Notify({ type: "danger", message: "支付失败" })
                }
              }
            )
          }
        }else{
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    calculateDate(num) {
      if (!num) return
      let starTime = this.getNowFormatDate()
      let nDate = new Date(starTime)
      let millSeconds = Math.abs(nDate) + ((parseInt(num) * 30) * 24 * 60 * 60 * 1000)
      let rDate = new Date(millSeconds)
      let year = rDate.getFullYear()
      let month = rDate.getMonth() + 1
      if (month < 10) month = "0" + month
      let date = rDate.getDate()
      if (date < 10) date = "0" + date
      return (year + "-" + month + "-" + date)
    }, 
    getNowFormatDate() {
      let date = new Date()
      let seperator1 = "-"
      let seperator2 = ":"
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = "0" + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate
      }
      let currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
      return currentdate
    }
  },
}
</script>

<style scoped lang="scss">
.recharge{
  .header-bg{
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 280px;
    top: 0;
    left: 0;
  }
  .recharge-container{
    .recharge-form{
      width: 690px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
      border-radius: 10px;
      padding: 58px 28px 0 28px;
      margin: 85px auto 230px;
      box-sizing: border-box;
      .form-item{
        margin-bottom: 52px;
        .title{
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          display: block;
          margin-bottom: 33px;
          text-align: left;
        }
        .recharge-ruletype{
          margin-bottom: 34px;
          padding-left: 13px;
          .van-radio{
            font-size: 24px;
            color: #000000;
            margin-right: 50px;
          }
        }
        .form-select, .form-input{
          width: 610px;
          height: 66px;
          background: #FFFFFF;
          border: 1px solid #DCDCDC;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          padding-left: 25px;
          box-sizing: border-box;
        }
      }
      .payment-type{
        .van-radio{
          display: flex;
          justify-content: space-between;
          height: 85px;
          border-bottom: 1px solid #ddd;
          padding: 0 5px;
          font-size: 24px;
          color: #333333;
        }
        .no-payment{
          font-size: 24px;
          color: #9a9a9a;
        }
        .paytype-icon{
          width: 38px;
          height: 38px;
          vertical-align: middle;
          margin-right: 19px;
        }
      }
    }
    .footer{
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 200px;
      background-color: #ffffff;
      .pay-amount{
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FF7F00;
        line-height: 60px;
        display: block;
        margin-bottom: 25px;
        margin-top: 10px;
      }
      .pay-btn{
        width: 600px;
        height: 80px;
        border-radius: 40px;
        font-size: 28px;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }
}
</style>