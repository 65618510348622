<template>
  <div class="present">
    <div class="present-card base-info">
      <span class="title">基本信息</span>
      <div class="base-item carlicense">
        <span><img class="icon-car" src="@/assets/image/che2.png" alt="">赠送车辆</span>
        <span>{{carLicense}}</span>
      </div>
      <div class="base-item">
        <span>停车时长</span>
        <span>{{parkingTime}}</span>
      </div>
      <div class="base-item">
        <span>停车费用</span>
        <span class="price">{{amountFinal}}元</span>
      </div>
    </div>
    <div class="present-card present-cont">
      <span class="title">请选择赠送类型</span>
      <van-radio-group v-model="presentType" checked-color="#FF7C2D" @change="switchPresentType">
        <van-radio v-show="surplusHours != 0" name="0" label-position="left" >时间券(已赠送{{couponTime}}小时)</van-radio>
        <van-radio v-show="surplusAmount != 0" name="1" label-position="left" >金额券(已赠送{{amountCoupon}}元)</van-radio>
        <!-- <van-radio name="2" label-position="left" >次卡(已赠送{{couponTime}}小时)</van-radio> -->
        <van-radio v-show="surplusHours != 0" name="3" label-position="left" >全部停车时长</van-radio>
        <van-radio v-show="surplusAmount != 0" name="4" label-position="left" >全部停车金额</van-radio>
      </van-radio-group>
    </div>
    <div class="present-card present-amount">
      <span class="title">{{titleText}}</span>
      <div v-if="presentType == '0' || presentType == '1'" class="amount-box">
        <van-stepper v-model="presentNumber" integer />
        <span class="unit">{{presentType == '1'?'元':'小时'}}</span>
      </div>
      <div v-else class="all-amount-box">
        本次共赠送全部停车{{presentType=='3'?'时长':'金额'}} <span class="present-number">{{presentType=='3'?presentAlltime:presentAllmoney}}</span> {{presentType=='3'?'小时':'元'}}
      </div>
    </div>
    <van-button class="linear-gradient present-btn" type="info" :loading="isLoading" loading-text="赠送中..." @click="present">赠 送</van-button>
  </div>
</template>

<script>
import { listBalance, totalPresent, parkingInfo, merchantPresent, getDetailedRecord } from "@/utils/api"
import { Toast, Notify } from 'vant'

export default {
  name: 'MerchantPresent',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      merchantInfo: null,
      recordId: '',
      carLicense: '',
      parkingLotId: '',
      entranceTime: '',
      presentNumber: 1,
      parkingTime: '',
      amountFinal: 0,
      amountCoupon: 0,
      couponTime: 0,
      amountCoupon: 0,
      presentType: '0',
      surplusAmount: 0,
      surplusHours: 0,
      presentRule: '',
      presentAlltime: 0,
      presentAllmoney: 0,
      titleText: '请输入赠送时长',
      isLoading: false
    }
  },
  computed: {},
  watch: {},
  created () {
    this.merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))
    const urlParam = this.$route.query
    this.recordId = urlParam.recordId
    this.carLicense = urlParam.carLicense
    this.parkingLotId = urlParam.parkingLotId
    this.entranceTime = urlParam.entranceTime

    this.getBalance()
    this.getTotalPresent()
    this.getParkingInfo()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getBalance () {
      let that = this

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
      }
      listBalance(param)
      .then(result => {
        if (result.status == "200") {
          that.surplusAmount = result.data.surplusAmount
          that.surplusHours = result.data.surplusHours
          that.presentRule = result.data.presentRule
          if(that.surplusAmount == 0 && that.surplusHours == 0){
            Notify({ type: "danger", message: '余额不足，请充值' })
            return
          }
          if(that.surplusHours != 0){
            that.presentType = '0'
            that.titleText = '请输入赠送时长'
          }else if(that.surplusAmount != 0){
            that.presentType = '1'
            that.titleText = '请输入赠送金额'
          }
        }else {
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    getTotalPresent () {
      let that = this

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
        recordId: this.recordId,
      }
      getDetailedRecord(param)
      .then(result => {
        if (result.status == "200") {
          that.couponTime = result.data.couponTime
          that.amountCoupon = result.data.amountCoupon
        }else {
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    getParkingInfo () {
      let that = this

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
        parkingLotId: this.parkingLotId,
        carLicense: this.carLicense
      }
      parkingInfo(param)
      .then(result => {
        if (result.status == "200") {
          that.parkingTime = result.data.parkingTime
          that.amountFinal = result.data.amountFinal
          let parkTime = that.getParkTime(result.data.entranceTime)
          that.presentAlltime = Math.ceil(parkTime/1000/3600)
          that.presentAllmoney = result.data.amountFinal
        }else {
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    switchPresentType (val){
      switch (val) {
        case '0':
          this.titleText = '请输入赠送时长'
          break;
        case '1':
          this.titleText = '请输入赠送金额'
          break;
        case '3':
          this.titleText = '赠送全部停车时长'
          break;
        case '4':
          this.titleText = '赠送全部停车金额'
          break;
        default:
          break;
      }
    },
    present (){
      let that = this
      this.isLoading = true
      if(this.surplusAmount == 0 && this.surplusHours == 0){
        Notify({ type: "danger", message: '余额不足，请充值' })
        this.isLoading = false
        return
      }
      let type = ''
      let number = 0
      if(this.presentType == '0'){
        if(this.surplusHours < this.presentNumber){
          Notify({ type: "danger", message: '时间账户余额不足，请充值' })
          this.isLoading = false
          return
        }else{
          type = 0
          number = this.presentNumber
        }
      }
      if(this.presentType == '1'){
        if(this.surplusAmount < this.presentNumber){
          Notify({ type: "danger", message: '金额账户余额不足，请充值' })
          this.isLoading = false
          return
        }else{
          type = 1
          number = this.presentNumber
        }
      }
      if(this.presentType == '3'){
        if(this.surplusHours < this.presentAlltime){
          Notify({ type: "danger", message: '时间账户余额不足，请充值' })
          this.isLoading = false
          return
        }else{
          type = 0
          number = this.presentAlltime
        }
      }
      if(this.presentType == '4'){
        if(this.surplusHours < this.presentAllmoney){
          Notify({ type: "danger", message: '金额账户余额不足，请充值' })
          this.isLoading = false
          return
        }else{
          type = 1
          number = this.presentAllmoney
        }
      }

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
        presentType: type,
        presentNumber: number,
        carLicense: this.carLicense,
        recordId: this.recordId
      }
      merchantPresent(param)
      .then(result => {
        if (result.status == "200") {
          Toast.success('赠送成功');
        }else {
          Notify({ type: "danger", message: result.message })
        }
        this.isLoading = false
      })
      .catch(err => {
        this.isLoading = false
        window.console.log(err);
      })
    },
    getParkTime (entranceTime){
      var now = Date.parse(new Date());
      var entranceTime = Date.parse(new Date(entranceTime.replace(/-/g, "/")));
      return now - entranceTime;
    }
  },
}
</script>

<style scoped lang="scss">
.present{
  display: flex;
  flex-direction: column;
  align-items: center;
  .present-card{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 35px 20px;
    margin-bottom: 33px;
    .title{
      font-size: 28px;
      font-weight: 500;
      color: #333333;
      display: block;
      text-align: left;
      margin-bottom: 15px;
    }
  }
  .base-info{
    width: 690px;
    height: 256px;
    margin-top: 20px;
    font-size: 22px;
    color: #666768;
    .base-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 40px;
    }
    .carlicense{
      border-bottom: 1px solid #dddddd;
      font-size: 24px;
      color: #333333;
      margin-bottom: 10px;
      line-height: 50px;
      .icon-car{
        width: 35px;
        height: 29px;
        margin-right: 9px;
        vertical-align: middle;
      }
    }
    .price{
      color: #FF7C2D;
    }
  }
  .present-cont{
    width: 690px;
    font-size: 24px;
    color: #333333;
    .van-radio{
      display: flex;
      justify-content: space-between;
      height: 85px;
      border-bottom: 1px solid #ddd;
      padding: 0 5px;
    }
  }
  .present-amount{
    width: 690px;
    height: 210px;
    .amount-box{
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .van-stepper{
        ::v-deep button{
          width: 50px;
          height: 50px;
          background: rgba(249, 249, 249, 0);
          border: 1px solid #BBBBBB;
          border-radius: 5px;
        }
        ::v-deep input{
          width: 418px;
          height: 50px;
          background: rgba(249, 249, 249, 0);
          border: 1px solid #BBBBBB;
          border-radius: 5px;
        }
      }
      .unit{
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
      }
    }
    .all-amount-box{
      font-size: 30px;
      text-align: right;
      margin-top: 30px;
      .present-number{
        font-size: 46px;
        color: #ff7c2d;
      }
    }
  }
  .present-btn{
    width: 600px;
    height: 80px;
    border-radius: 4px;
    font-size: 28px;
    margin-top: 70px;
    margin-bottom: 50px;
  }
}
</style>