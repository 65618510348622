<template>
  <div class="merchant-login">
    <div class="head">
      <img class="wx-heae-img" :src="userHeadImg" alt="">
    </div>
    <div class="login-form">
      <div class="login-form-header">
        <div class="login-tab account-tab" :class="{active:loginWay == 0}" @click="loginWay = 0">
          <span class="tab-title">账户登录</span>
          <van-icon name="arrow-down" v-show="loginWay == 0" />
        </div>
        <div class="login-tab phone-tab" :class="{active:loginWay == 1}" @click="loginWay = 1">
          <span class="tab-title">手机号登录</span>
          <van-icon name="arrow-down" v-show="loginWay == 1" />
        </div>
      </div>
      <div class="account-login" v-if="loginWay == 0">
        <div class="form-item">
          <img class="img-user" src="@/assets/image/login-user.png" alt="">
          <input type="text" class="account" v-model="account" placeholder="请输入您的账号" maxlength="20">
        </div>
        <div class="form-item">
          <img class="img-pwd" src="@/assets/image/login-pwd.png" alt="">
          <input :type="inputType" class="password" v-model="password" placeholder="请输入您的密码" maxlength="20">
          <img v-if="inputType == 'password'" class="img-eye-close" src="@/assets/image/pwd-eye-close.png" alt="" @click="inputType='text'">
          <img v-else class="img-eye-open" src="@/assets/image/pwd-eye-open.png" alt="" @click="inputType='password'">
        </div>
      </div>
      <div class="phone-login" v-else>
        <div class="form-item">
          <img class="img-phone" src="@/assets/image/login-phone.png" alt="">
          <input type="text" class="phone" v-model="phone" placeholder="请输入手机号" maxlength="11">
        </div>
        <div class="form-item">
          <img class="img-testing" src="@/assets/image/login-testing.png" alt="">
          <input type="text" class="verify-code" v-model="verifyCode" placeholder="请输入验证码" maxlength="6">
          <span class="verify-code-btn" @click="clickFlag && getVerifyCode()">{{ codeBtnText }}</span>
        </div>
      </div>
      <div class="login-form-footer">
        <van-button class="linear-gradient login-btn" type="info" @click="login">
          登 录
        </van-button>
        <span class="notification">登录即表示您已阅读并同意<a @click="toAgreement">《泊士停车服务协议》</a></span>
      </div>
    </div>
    <van-dialog class="verify-code-dialog" v-model="verifyCodeDialog" show-cancel-button :before-close="beforeClose">
      <span class='dialog-span'>即将发送验证码到</span>
      <p>{{ setPhone }}</p>
      <span class='dialog-span'>稍后您将收到系统发送的验证码，请将<br/>验证码填写到验证码输入框<br/>以完成手机号绑定</span>
    </van-dialog>
  </div>
</template>

<script>
import { apiVerificationCode, SendSms, updateUserPhone, webLogin, webLoginV2 } from "@/utils/api"
import { Toast, Dialog, Notify } from 'vant'
import avatar from '@/assets/image/photo.png';

export default {
  name: 'MerchantLogin',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      phone: '',
      verifyCode: '',
      account: '',
      password: '',
      verifyCodeDialog: false,
      userHeadImg: '',
      timer: null,
      codeBtnText: '获取验证码',
      clickFlag: true,
      isGetCode: false,
      loginWay: 0,
      inputType: 'password',
      guid: ''
    }
  },
  computed: {
    setPhone () {
      return this.phone.slice(0,3) + '****' + this.phone.substr(7,4)
    }
  },
  watch: {},
  created () {
    this.userHeadImg = avatar
    this.guid = this.setGuid()
    this.account = localStorage.getItem('merchantLoginName') ? localStorage.getItem('merchantLoginName') : ''
  },
  mounted () {},
  destroyed () {},
  methods: {
    sendCode() {
      let that = this;

      let times = 60;
      const param = {
        phone: this.phone,
        smsTemplate: 6
      }
      SendSms(param)
        .then(result => {
          if (result.status == "200") {
            that.isGetCode = true
            that.timer = setInterval(function() {
              times--;
              if (times == 0 || times < 0) {
                that.codeBtnText = "获取验证码"
                that.clickFlag = true
                clearInterval(that.timer)
                that.timer = null
              } else {
                that.codeBtnText = "重新获取" + times + "s"
                that.clickFlag = false
              }
            }, 1000);
            this.verifyCodeDialog = false
            Notify({
              message: "验证码已发送,请注意查收",
              background: "#16AF99"
            });
          } else {
            Notify({ type: "danger", message: res.message })
          }
        })
        .catch(err => {
          window.console.log(err);
        })
    },
    getVerifyCode () {
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        Notify({ type: "danger", message: "请填写正确的手机号" })
        return
      }
      this.verifyCodeDialog = true
    },
    beforeClose (action, done) {
      if (action === 'confirm') {
        this.sendCode()
        done(); // 关闭提示框
      } else if (action === 'cancel') { // 取消
        done(); // 关闭提示框
      }
    },
    login (){
      let that = this
      let requestParam = {}
      if(this.loginWay == 0){
        if(this.account == ''){
          Toast('请输入账号')
          return
        }
        if(this.password == ''){
          Notify({ type: "danger", message: "请输入密码" })
          return
        }
        requestParam = {
          userAccount: this.account,
          userPassword: this.password,
          codeNum: this.guid,
          isMerchant: 1,
          isSlide: 2,
          code: '',
          loginIp: ''
        }

        webLogin(requestParam)
          .then(result => {
            if (result.status == "200") {
              localStorage.setItem("merchantInfo", JSON.stringify(result.data))
              localStorage.setItem("merchantLoginName", that.account)
              that.$router.push('/merchant/home')
            } else {
              Notify({ type: "danger", message: result.message })
            }
          })
          .catch(err => {
            window.console.log(err)
          })
      }else{
        if(this.phone == ''){
          Toast('请输入手机号')
          return
        }
        if(!this.isGetCode){
          Toast('请获取短信验证码')
          return
        }
        if(this.verifyCode.length != 6){
          Notify({ type: "danger", message: "请填写6位验证码" })
          return
        }
        requestParam = {
          userAccount: '',
          userPassword: '',
          codeNum: this.guid,
          isMerchant: 1,
          isSlide: '',
          percent: '',
          loginIp: '',
          loginType: 1,
          phone: this.phone,
          vcode: this.verifyCode,
        }

        webLoginV2(requestParam)
          .then(result => {
            if (result.status == "200") {
              localStorage.setItem("merchantInfo", JSON.stringify(result.data))
              localStorage.setItem("merchantLoginName", result.data.account)
              that.$router.replace('/merchant/home')
            } else {
              Notify({ type: "danger", message: result.message })
            }
          })
          .catch(err => {
            window.console.log(err)
          })
      }
    },
    toAgreement (){
      this.$router.push('/agreement')
    },
    setGuid (){
      function S4() {
          return (((1+Math.random())*0x10000)|0).toString(16).substring(1)
      }
      return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4())
    }
  }
}
</script>

<style scoped lang="scss">
.merchant-login{
  width: 100%;
  height: 100vh;
  background: url('../../assets/image/merchant-login-bg.png');
  background-size: cover;
  .head{
    padding-top: 140px;
    .wx-heae-img{
      width: 128px;
      height: 128px;
      display: inline-block;
      border-radius: 50%;
      // border: 1px solid #d0ddfe;
      margin-bottom: 32px;
    }
  }
  .login-form{
    margin: 0 auto;
    width: 544px;
    height: 525px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
    border-radius: 10px;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .login-form-header{
      width: 100%;
      height: 140px;
      display: flex;
      justify-content: space-around;
      font-size: 24px;
      color: #333333;
      .login-tab .tab-title{
        display: block;
        margin-top: 45px;
        margin-bottom: 16px;
      }
      .login-tab.active{
        color: #26A0A5;
      }
    }
    .form-item{
      width: 473px;
      height: 69px;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      margin-bottom: 40px;
      line-height: 1;
      font-size: 24px;
      display: flex;
      align-items: center;
      position: relative;
      input{
        border: none;
        height: 55px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
      input.phone, input.account, input.password{
        width: 100%;
      }
      .img-phone{
        width: 30px;
        height: 43px;
        margin-left: 20px;
        margin-right: 17px;
      }
      .img-testing{
        width: 35px;
        height: 35px;
        margin-left: 18px;
        margin-right: 14px;
      }
      .img-user{
        width: 30px;
        height: 35px;
        margin-left: 20px;
        margin-right: 17px;
      }
      .img-pwd{
        width: 29px;
        height: 38px;
        margin-left: 20px;
        margin-right: 17px;
      }
      .img-eye-open{
        width: 31px;
        height: 24px;
        padding: 16px 22px;
      }
      .img-eye-close{
        width: 29px;
        height: 15px;
        padding: 16px 25px;
      }
      .verify-code-btn{
        height: 22px;
        font-size: 23px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        position: absolute;
        right: 0;
        padding: 22px 18px;
      }
    }
    .login-btn{
      width: 473px;
      height: 70px;
      border-radius: 35px;
      // margin-bottom: 30px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      display: block;
    }
    .notification{
      width: 357px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 34px;
      a{
        color: #73b7ce;
      }
    }
  }
  .verify-code-dialog{ 
    border-radius: 8px;
    ::v-deep .van-dialog__content{
      padding: 55px 26px 40px;
      .dialog-span{
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 48px;
      }
      p{
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
    }
  }
}
</style>