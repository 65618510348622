<template>
  <div class="qrcode-paper-add">
    <img class="header-bg" src="@/assets/image/bjinformation2.png" alt="">
    <div class="add-form">
      <div class="form-item">
        <span class="title">领取方式</span>
        <select v-model="presentType" class="form-select">
          <option value="0">固定时长</option>
          <option value="1">固定金额</option>
          <option value="3">匹配停车时长</option>
          <option value="4">匹配停车金额</option>
        </select>
      </div>
      <div v-show="presentType == 0 || presentType == 1" class="form-item">
        <span class="title">领取额度（{{presentType == 0?'小时':'元'}}）</span>
        <input type="number" v-model="presentNumber" class="form-input" placeholder="请输入领取额度">
      </div>
      <div class="form-item">
        <span class="title">过期时间</span>
        <input type="text" v-model="expDate" class="form-input" @click="showDate = true" placeholder="请选择过期时间">
      </div>
      <div style="height:1px;"></div>
    </div>
    <van-button class="linear-gradient add-btn" type="info" @click="addPaper">添加</van-button>

    <van-popup v-model="showDate" position="bottom" round>
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择过期时间"
        :min-date="minDate"
        @confirm="getDate"
        @cancel="showDate=false"
      />
    </van-popup>

  </div>
</template>

<script>
import { addPaperQRCode } from "@/utils/api"
import { Toast, Notify } from 'vant'

export default {
  name: 'QrcodePaperAdd',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      merchantInfo: null,
      presentType: 0,
      presentNumber: 1,
      expDate: '',
      showDate: false,
      minDate: new Date(),
      currentDate: new Date(),
    }
  },
  computed: {},
  watch: {},
  created () {
    this.merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))
    const urlParam = this.$route.query
  },
  mounted () {},
  destroyed () {},
  methods: {
    addPaper () {
      let that = this
      if((this.presentType == 0 || this.presentType == 1) && this.presentNumber < 1){
        Toast('请输入领取额度')
        return
      }
      if(this.expDate == ''){
        Toast('请选择过期日期')
        return
      }

      let param = {
        uid: this.merchantInfo.uid,
        unitID: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
				expDate: this.expDate,
				presentType: this.presentType,
				presentNumber: this.presentNumber
      }
      addPaperQRCode(param)
      .then(result => {
        if (result.status == "200") {
          Toast.success({
            message: '添加成功',
            onClose: function(){
              that.$router.replace({path: '/merchant/qrcode-paper'})
            }
          })
        }else {
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    getDate (value){
      let y = value.getFullYear()
      let m = value.getMonth()+1
      let d = value.getDate()
      this.expDate = y+'-'+ (m < 10 ? '0'+m: m) +'-'+ (d < 10 ? '0'+d: d)
      this.showDate = false
    }
  },
}
</script>

<style scoped lang="scss">
.qrcode-paper-add{
  .header-bg{
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 280px;
    top: 0;
    left: 0;
  }
  .add-form{
    width: 690px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
    border-radius: 10px;
    padding: 58px 28px 0 28px;
    margin: 85px auto 56px;
    box-sizing: border-box;
    .form-item{
      margin-bottom: 52px;
      .title{
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        display: block;
        margin-bottom: 33px;
        text-align: left;
      }
      .recharge-ruletype{
        margin-bottom: 34px;
        padding-left: 13px;
      }
      .form-select, .form-input{
        width: 610px;
        height: 66px;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        padding-left: 25px;
        box-sizing: border-box;
      }
    }
  }
  .add-btn{
    width: 600px;
    height: 80px;
    border-radius: 4px;
  }
}
</style>