<template>
  <div class="carlist">
    <div v-for="item in carlist" :key="item.recordId" class="car-item">
      <img :src="item.entrancePhoto?'https://image.scsonic.cn/'+item.entrancePhoto:defaultCarImg" class="car-img" alt="">
      <div class="car-info">
        <div class="carlicense">车牌号：{{item.carLicense}}</div>
        <div class="time-in">入场时间：{{item.entranceTime}}</div>
        <div class="parkinglot">
          <span class="parkinglot-name van-ellipsis">停车场：{{item.parkingLotName}}</span>
          <span class="present-btn" @click="toPresent(item)">赠送</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listCarRecord } from "@/utils/api"
import { Toast, Notify } from 'vant'
import defaultImg from '@/assets/image/merchant-car-imgbg.png';

export default {
  name: 'MerchantCarlist',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      merchantInfo: null,
      carLicense: '',
      carlist: [],
      defaultCarImg: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))
    const urlParam = this.$route.query
    this.carLicense = urlParam.carLicense
    this.defaultCarImg = defaultImg

    this.getCarList()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getCarList () {
      let that = this

      let param = {
        carLicense: this.carLicense,
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
      }
      listCarRecord(param)
      .then(result => {
        if (result.status == "200") {
          that.carlist = result.data
        }else {
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    toPresent (carInfo){
      this.$router.push({
        path: '/merchant/present',
        query: carInfo
      })
    }
  },
}
</script>

<style scoped lang="scss">
.carlist{
  .car-item{
    width: 690px;
    height: 204px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
    border-radius: 6px;
    margin: 36px auto;
    box-sizing: border-box;
    padding: 25px 16px 25px 12px;
    display: flex;
    .car-img{
      width: 238px;
      height: 153px;
      border-radius: 4px;
    }
    .car-info{
      font-size: 24px;
      color: #494949;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 2px 0 10px 20px;
      position: relative;
      .carlicense{
        font-size: 28px;
        color: #111111;
      }
      .parkinglot-name{
        max-width: 300px;
        display: inline-block;
      }
      .present-btn{
        position: absolute;
        right: 0;
        width: 104px;
        height: 35px;
        background: #26A0A5;
        border-radius: 18px;
        display: inline-block;
        color: #fff;
      }
    }
  }
}
</style>