<template>
  <div class="qrcode-static">
    <form action="/">
      <van-search v-model="keyword" shape="round" maxlength="20" background="#ffffff" @search="onSearch" placeholder="可输入二维码名称查询" />
    </form>
    <div class="qrcode-list">
      <div class="qrcode" v-for="item in qrcodeList" :key="item.qrCodeId" @click="showQrcode(item)">
        <!-- <div style="display:flex;align-items:center;margin-bottom:6px;"><span class="qrcode-name van-ellipsis">{{item.qrCodeName}}</span><span class="qrcode-used" :class="{'noused': item.status==1}">{{ item.status | setStatus }}</span></div> -->
        <div v-if="item.status==2" style="display:flex;align-items:center;margin-bottom:6px;"><span class="qrcode-name van-ellipsis">{{item.qrCodeName}}</span><span class="qrcode-used">{{ item.copiesUsed }}/{{ item.copies }}</span></div>
        <div v-else style="display:flex;align-items:center;margin-bottom:6px;"><span class="qrcode-name van-ellipsis">{{item.qrCodeName}}</span><span class="qrcode-used" :class="{'noused': item.status==1}">{{ item.status | setStatus }}</span></div>
        <span class="qrcode-type">赠送类型：{{item.presentType | setType}}</span>
        <span class="qrcode-unused-number">剩余数量：{{item.copies == '无限制'? '无限制' : (item.copies - item.copiesUsed)}}</span>
        <span class="qrcode-expdate">过期时间：{{item.expDate}}</span>
        <div class="present-number">
          <p v-if="item.presentType == 0 || item.presentType == 1"><span class="amount-number">{{item.presentNumber}}</span><span class="unit">{{item.presentType == 0?'小时':'元'}}</span></p>
          <p v-else><span class="amount-number2">{{item.presentType == 3?'全部时长':'全部金额'}}</span></p>
          <span>赠送数额</span>
        </div>
      </div>
    </div>
    <p v-if="qrcodeList.length == 0" class="no-data">未查询到数据</p>
    <van-button v-else class="more-btn" :loading="isLoading" loading-text="加载中..." type="primary" block @click="getMore">{{moreText}}</van-button>

    <van-overlay :show="shadeShow">
      <div class="wrapper" @click.stop="shadeShow = false">
        <div class="qrcode-box">
          <span class="qrcode-name">{{selectQrcode.name}}</span>
          <span class="des">将此码展示给车主</span>
          <div class="qrcode-img" ref="qrCodeUrl"></div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { qrcodeList } from "@/utils/api"
import { Toast, Notify } from 'vant'
import QRCode from 'qrcodejs2'

export default {
  name: 'QrcodeStatic',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      merchantInfo: null,
      keyword: '',
      shadeShow: false,
      currentPage: 1,
      pageSize: 20,
      qrcodeList: [],
      isLoading: false,
      moreText: '展示更多',
      selectQrcode: {
        name: '',
        id: ''
      },
      qrcode: '',
      domain: ''
    }
  },
  computed: {},
  watch: {},
  filters: {
    setStatus: function (value) {
      switch (value) {
        case 1:
          return '未使用'
          break;
        case 2:
          return '已使用'
          break;
        case 3:
          return '已过期'
          break;
        case 4:
          return '已删除'
          break;
        default:
          return '无状态'
          break;
      }
    },
    setType: function(value) {
      switch (value) {
        case 0:
        case 3:
          return '时间券'
          break;
        case 1:
        case 4:
          return '金额券'
          break;
        default:
          return '无类型'
          break;
      }
    }
  },
  created () {
    this.merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))
    const urlParam = this.$route.query
    this.domain = window.location.origin

    this.getQrcodeList()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getQrcodeList () {
      let that = this

      let param = {
        uid: this.merchantInfo.uid,
        unitID: this.merchantInfo.unitId,
        currentPage: this.currentPage ,
        pageSize: this.pageSize ,
        qrcodeType: 1 ,
        qrCodeName: this.keyword,
        token: this.merchantInfo.token,
      }
      qrcodeList(param)
      .then(result => {
        if (result.status == "200") {
          if(that.currentPage == 1){
            that.qrcodeList = result.data
            if(result.data.length != 0){
              that.currentPage++
            }
          }else{
            if(result.data.length != 0){
              that.qrcodeList = [...that.qrcodeList, ...result.data]
              that.currentPage++
            }else{
              that.moreText = "我是有底线的"
            }
          }
          that.isLoading = false
        }else {
          Notify({ type: "danger", message: result.message })
          that.isLoading = false
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    onSearch (val){
      this.keyword = val
      this.currentPage = 1
      this.moreText = '展示更多'
      this.isLoading = false
      this.getQrcodeList()
    },
    showQrcode (item){
      this.selectQrcode.name = item.qrCodeName
      this.shadeShow = true
      this.creatQrCode(item)
    },
    getMore (){
      if(this.moreText == '展示更多'){
        this.isLoading = true
        this.getQrcodeList()
      }
    },
    creatQrCode(data) {
      let qrcodeText = this.domain+'/q/'+data.qrCodeId
      if(!this.qrcode){
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: qrcodeText,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
      }else{
        this.qrcode.clear()
        this.qrcode.makeCode(qrcodeText)
      }
      this.$nextTick(() =>{
        let qrcode = document.querySelector('.qrcode-img img')
        qrcode.style.width = '100%'
        let canvas = document.querySelector('.qrcode-img canvas')
        canvas.style.width = '100%'
      })
    },
  },
}
</script>

<style scoped lang="scss">
.qrcode-static{
  font-size: 24px;
  color: #333333;
  .van-search{

  }
  .qrcode-list{
    width: 100%;
    text-align: center;
    .qrcode{
      width: 729px;
      height: 239px;
      margin: 0 auto;
      background: url('../../assets/image/coupon-bg-nouse.png');
      background-size: contain;
      position: relative;
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      padding-left: 68px;
      box-sizing: border-box;
      .qrcode-type, .qrcode-unused-number, .qrcode-expdate{
        display: inline-block;
        line-height: 35px;
      }
      .qrcode-name{
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 20px;
        max-width: 300px;
      }
      .qrcode-used{
        width: 88px;
        height: 29px;
        background: #FE6402;
        border-radius: 2px;
        font-size: 18px;
        font-weight: 400;
        color: #FFF8F8;
        display: inline-block;
        text-align: center;
        line-height: 29px;
      }
      .noused{
        background: #EEEEEE;
        color: #848484;
      }
      .present-number{
        position: absolute;
        right: 18px;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        width: 200px;
        height: 239px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          margin: 0;
        }
        .amount-number{
          font-size: 50px;
          margin: 0;
        }
        .amount-number2{
          font-size: 40px;
        }
      }
    }
  }
  .no-data{
    text-align: center;
    color: gray;
  }
  .more-btn{
    border: 0;
    border-radius: 0;
    background: #fff;
    color: #a9a9a9;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .qrcode-box {
      width: 480px;
      height: 627px;
      background: url('../../assets/image/qrcode-bg.png');
      background-size: contain;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-repeat: no-repeat;
      .qrcode-name{
        font-size: 38px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 116px;
        line-height: 1.2;
        max-width: 375px;
        height: 118px;
        word-wrap:break-word;
        display: flex;
        align-items: center;
        word-break: break-all;
      }
      .des{
        font-size: 23px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 80px;
      }  
      .qrcode-img{
        width: 375px;
        height: 375px;
        canvas, img{
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

}
</style>