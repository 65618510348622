<template>
  <div class="update-pwd">
    <div class="head">
      <img class="wx-heae-img" :src="userHeadImg" alt="">
    </div>
    <div class="login-form">
      <div class="form-item">
        <img class="img-pwd" src="@/assets/image/login-pwd.png" alt="">
        <input :type="inputType1" class="password" v-model="oldPwd" placeholder="请输入旧密码" maxlength="20">
        <img v-if="inputType1 == 'password'" class="img-eye-close" src="@/assets/image/pwd-eye-close.png" alt="" @click="inputType1='text'">
        <img v-else class="img-eye-open" src="@/assets/image/pwd-eye-open.png" alt="" @click="inputType1='password'">
      </div>
      <div class="form-item">
        <img class="img-pwd" src="@/assets/image/login-pwd.png" alt="">
        <input :type="inputType2" class="password" v-model="newPwd" placeholder="请输入新密码" maxlength="20">
        <img v-if="inputType2 == 'password'" class="img-eye-close" src="@/assets/image/pwd-eye-close.png" alt="" @click="inputType2='text'">
        <img v-else class="img-eye-open" src="@/assets/image/pwd-eye-open.png" alt="" @click="inputType2='password'">
      </div>
      <div class="form-item">
        <img class="img-pwd" src="@/assets/image/login-pwd.png" alt="">
        <input :type="inputType3" class="password" v-model="newPwd2" placeholder="请确认新密码" maxlength="20">
        <img v-if="inputType3 == 'password'" class="img-eye-close" src="@/assets/image/pwd-eye-close.png" alt="" @click="inputType3='text'">
        <img v-else class="img-eye-open" src="@/assets/image/pwd-eye-open.png" alt="" @click="inputType3='password'">
      </div>
      <van-button class="linear-gradient update-btn" type="info" @click="updatePwd">
        确认修改
      </van-button>
    </div>

    <van-dialog class="dialog-res" v-model="show" confirm-button-text="去登录" confirm-button-color="#FE6402" @closed="closeDialog">
      <span class="dialog-cont">密码修改成功，请重新登录</span>
    </van-dialog>
  </div>
</template>

<script>
import { updateAccountPassword } from "@/utils/api"
import { closeWebviewEvent } from "@/utils/common";
import { Toast, Notify } from 'vant'
import avatar from '@/assets/image/photo.png';

export default {
  name: 'MerchantUpdatePwd',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      userHeadImg: '',
      merchantInfo: '',
      oldPwd: '',
      newPwd: '',
      newPwd2: '',
      inputType1: 'password',
      inputType2: 'password',
      inputType3: 'password',
      show: false
    }
  },
  computed: {},
  watch: {},
  created () {
    this.merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))
    this.userHeadImg = this.merchantInfo.userHead?'https://image.scsonic.cn/'+this.merchantInfo.userHead:avatar

    // window.addEventListener("popstate", closeWebviewEvent, false)
  },
  mounted () {},
  destroyed () {},
  methods: {
    updatePwd (){
      let that = this
      if(this.oldPwd == ''){
        Notify({ type: "danger", message: "请输入旧密码" })
        return
      }
      if(this.newPwd == ''){
        Notify({ type: "danger", message: "请输入新密码" })
        return
      }
      if(this.newPwd2 != this.newPwd ){
        Notify({ type: "danger", message: "两次输入的新密码不一致" })
        return
      }
      let param = {
        token: this.merchantInfo.token,
        uid: this.merchantInfo.uid,
        loginName: localStorage.getItem('merchantLoginName'),
        loginPwd: this.oldPwd,
        newPwd: this.newPwd
      }
      updateAccountPassword(param)
        .then(result => {
          if (result.message !== '原密码不正确') {
            localStorage.removeItem("merchantInfo")
            that.show = true
          } else {
            Notify({ type: "danger", message: result.message })
          }
        })
        .catch(err => {
          window.console.log(err);
        })
    },
    closeDialog() {
      this.$router.replace('/merchant/login')
      // closeWebviewEvent()
    }
  }
}
</script>

<style scoped lang="scss">
.update-pwd{
  width: 100%;
  height: 100vh;
  background: url('../../assets/image/merchant-login-bg.png');
  background-size: cover;
  .head{
    padding-top: 140px;
    .wx-heae-img{
      width: 128px;
      height: 128px;
      display: inline-block;
      border-radius: 50%;
      // border: 1px solid #d0ddfe;
      margin-bottom: 32px;
    }
  }
  .login-form{
    margin: 0 auto;
    width: 544px;
    height: 478px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .form-item{
      width: 473px;
      height: 69px;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      margin-bottom: 35px;
      line-height: 1;
      font-size: 24px;
      display: flex;
      align-items: center;
      position: relative;
      input{
        border: none;
        height: 55px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        width: 100%;
      }
      .img-pwd{
        width: 29px;
        height: 38px;
        margin-left: 20px;
        margin-right: 17px;
      }
      .img-eye-open{
        width: 31px;
        height: 24px;
        padding: 16px 22px;
      }
      .img-eye-close{
        width: 29px;
        height: 15px;
        padding: 16px 25px;
      }
    }
    .update-btn{
      width: 473px;
      height: 70px;
      border-radius: 35px;
      margin-top: 8px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
  .dialog-res{
    border-radius: 8px;
    ::v-deep .van-dialog__content{
      padding: 90px 0 50px;
      .dialog-cont{
        font-size: 38px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
      }
    }
  }
}
</style>