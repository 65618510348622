<template>
  <div class="recharge-records">
    <div class="header">
      <div class="title">我的资产</div>
      <div>时间账户：余{{surplusHours}}小时</div>
      <div>余额账户：余{{surplusAmount}}元</div>
    </div>
    <div class="record-list">
      <div class="title">充值记录</div>
      <p v-if="records.length == 0" class="no-data">未查询到数据</p>
      <template v-else >
        <div class="record" v-for="item in records" :key="item.recordId">
          <div><span class="recharge-type">{{item.ruleType == 0?'时间账户':'金额账户'}}</span><span class="recharge-number">{{item.ruleQuota}}{{item.ruleType == 0?'小时':'元'}}</span></div>
          <div><span class="recharge-time">{{item.createDate}}</span><span class="recharge-amount">充值金额：{{item.payAmount}}元</span></div>
        </div>
        <van-button class="more-btn" :loading="isLoading" loading-text="加载中..." type="primary" block @click="getMore">{{moreText}}</van-button>
      </template>
    </div>
  </div>
</template>

<script>
import { merchantProperty, rechargeRecords } from "@/utils/api"
import { Toast, Notify } from 'vant'

export default {
  name: 'MerchantRechargeRecords',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      merchantInfo: null,
      records: [],
      surplusHours: 0,
      surplusAmount: 0,
      currentPage: 1,
      pageSize: 20,
      isLoading: false,
      moreText: '展示更多',
    }
  },
  computed: {},
  watch: {},
  created () {
    this.merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))
    const urlParam = this.$route.query

    this.getMerchantProperty()
    this.getRechargeRecords()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getMerchantProperty () {
      let that = this

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
      }
      merchantProperty(param)
      .then(result => {
        if (result.status == "200") {
          that.surplusHours = result.data.surplusHours
          that.surplusAmount = result.data.surplusAmount
        }else {
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    getRechargeRecords () {
      let that = this

      let param = {
        uid: this.merchantInfo.uid,
        unitId: this.merchantInfo.unitId,
        token: this.merchantInfo.token,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      }
      rechargeRecords(param)
      .then(result => {
        if (result.status == "200") {
          if(that.currentPage == 1){
            if(result.data.length != 0){
              that.records = result.data
              that.currentPage++
            }
          }else{
            if(result.data.length != 0){
              that.records = [...that.records, ...result.data]
              that.currentPage++
            }else{
              that.moreText = "我是有底线的"
            }
          }
          that.isLoading = false
        }else {
          Notify({ type: "danger", message: result.message })
          that.isLoading = false
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    getMore (){
      if(this.moreText == '展示更多'){
        this.isLoading = true
        this.getRechargeRecords()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.recharge-records{
  display: flex;
  flex-direction: column;
  align-items: center;
  .header{
    width: 737px;
    height: 286px;
    background: url('../../assets/image/jilu-bg.png');
    background-size: contain;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    padding: 55px 70px;
    box-sizing: border-box;
    text-align: left;
    div{
      line-height: 48px;
    }
    .title{
      font-size: 38px;
      line-height: 80px;
    }
  }
  .record-list{
    margin-top: 30px;
    width: 660px;
    .title{
      font-size: 30px;
      font-weight: 400;
      color: #333333;
      text-align: left;
    }
    .no-data{
      text-align: center;
      color: gray;
      font-size: 24px;
    }
    .more-btn{
      border: 0;
      border-radius: 0;
      background: #fff;
      color: #a9a9a9;
    }
    .record{
      width: 660px;
      height: 130px;
      box-shadow: 0px 1px 0px 0px rgba(145, 145, 145, 0.72);
      font-size: 28px;
      font-weight: 500;
      color: #222222;
      padding: 35px 15px 10px 15px;
      box-sizing: border-box;
      > div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 45px;
      }
      .recharge-number{
        font-size: 30px;
        font-weight: 500;
        color: #FF2B2B;
      }
      .recharge-time, .recharge-amount{
        font-size: 22px;
        color: #A2A2A2;
      }
    }
  }
}
</style>