<template>
  <div class="qrcode-share">
    <div class="qrcode-box">
      <span class="qrcode-name">停车优惠券</span>
      <span class="des">出口将此码放到扫码枪前扫描<br>可抵扣相应的停车费</span>
      <div class="qrcode-img" ref="qrCodeUrl"></div>
    </div>
  </div>
</template>

<script>
import { Toast, Notify } from 'vant'
import QRCode from 'qrcodejs2'

export default {
  name: 'QrcodeShare',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      qrcode: '',
      qrcodeId: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    const urlParam = this.$route.query
    this.qrcodeId = urlParam.id

    // this.creatQrCode()
  },
  mounted () {
    this.creatQrCode()
  },
  destroyed () {},
  methods: {
    creatQrCode() {
      this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.qrcodeId,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
      })
      this.$nextTick(() =>{
        let qrcode = document.querySelector('.qrcode-img img')
        qrcode.style.width = '100%'
        let canvas = document.querySelector('.qrcode-img canvas')
        canvas.style.width = '100%'
      })
    },
  },
}
</script>

<style scoped lang="scss">
.qrcode-share{
  background: url('../../assets/image/share-bg.png');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .qrcode-box {
    width: 480px;
    height: 627px;
    background: url('../../assets/image/qrcode-bg.png');
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    .qrcode-name{
      font-size: 38px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      line-height: 1.2;
      max-width: 375px;
      height: 118px;
      word-wrap:break-word;
      display: flex;
      align-items: center;
      word-break: break-all;
    }
    .des{
      font-size: 23px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 1.5;
      padding: 5px 0;
      letter-spacing: 1px;
    }  
    .qrcode-img{
      width: 375px;
      height: 375px;
      margin-top: 16px;
      margin-bottom: 20px;
    }
  }
}
</style>